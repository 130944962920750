

import { pagePropsSelectorFactory } from 'v2/redux/page/selectors';
import { createSelector } from 'reselect';
import { selectV2ModelsDataByType, createEntityByIdSelector } from 'v2/redux/selectors';
// import { createSelector } from 'reselect';


export const getV2DocumentId = pagePropsSelectorFactory('documentId');
export const selectV2PrevNextDocumentData = pagePropsSelectorFactory('prevNextDocumentData', true);
export const selectV2Document = createEntityByIdSelector(getV2DocumentId, selectV2ModelsDataByType.document);
