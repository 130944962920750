// TODO: refactor - for some reason this helper ignores timezone
// campaignEnds: "2021-11-29T21:00:00+0000",
// output => {formattedEndDate: '9:00 PM GMT+3 January 2nd, 2022'}
const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export default getUTCDate;
