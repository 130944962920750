import isValid from 'date-fns/isValid';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import getUTCDate from 'shared/helpers/utils/getUTCDate';

// TODO: Add Localization
const formatDate = (timestamp, { revertDay } = {}) => {
  try {
    if (typeof timestamp !== 'string') return {};

    const date = getUTCDate(timestamp);
    const dayMonth = revertDay ? 'MMM d' : 'd MMM';
    const justNowText = 'Just now';

    if (timestamp && isValid(date)) {
      const currentDate = getUTCDate();
      // Math.abs is requred to avoid negative numbers when date > currentDate (i.e. - 17 hours from now)
      const diffInHours = Math.abs(differenceInHours(currentDate, date));
      const diffInDays = Math.abs(differenceInDays(currentDate, date));
      let short;
      let diff = {};
      const postfix = currentDate > date ? 'ago' : 'from_now';

      if (diffInHours <= 24) {
        if (diffInHours < 1) {
          const diffInMinutes = Math.abs(differenceInMinutes(currentDate, date));
          short = `${diffInMinutes}m`;
          if (diffInMinutes === 0) {
            // TODO: i18n fix
            short = justNowText;
          }
          diff = { units: diffInMinutes, unitType: 'minutes' };
        } else {
          short = `${diffInHours}h`;
          diff = { units: diffInHours, unitType: 'hours' };
        }
      } else if (diffInDays <= 14) {
        short = format(date, dayMonth);
        diff = { units: diffInDays, unitType: 'days' };
      } else {
        short = format(date, `${dayMonth} yyyy`);
        diff = { units: diffInDays, unitType: 'days' };
      }

      let shortWithPrefix;
      if (short.match(/\d[h|m]/)) {
        if (short !== justNowText) {
          shortWithPrefix = `${short} ${postfix}`;
        } else {
          shortWithPrefix = short;
        }
      } else {
        shortWithPrefix = short;
      }

      return {
        short,
        shortWithPrefix,
        diff: { ...diff, postfix },
        full: format(date, `hh:mm a - ${dayMonth} yyyy`),
        simpleDateFormat: format(date, `${dayMonth}, yyyy`),
      };
    }
    return {};
  } catch (e) {
    console.error(e);
    return {};
  }
};

export default formatDate;
