import React from 'react';
import { shape, func } from 'prop-types';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
// import { isBrowser } from 'helpers/utils';
import { ROUTES } from 'helpers/constants';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './BackButton.module.scss';
import ampStylesObj from './BackButton.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'BackButton');


const goBack = (evt) => {
  if (window?.history.length > 1) {
    window?.history.back();
  } else {
    window?.location.assign(ROUTES.homeworkHelp);
  }
};

const HistoryBackButton = ({
  className,
  iconProps,
  styles,
  iconOnClick,
  href,
  ...restProps
}) => (
  <Link href={href} onClick={href ? null : iconOnClick} className={cn(className, styles.container)} {...restProps}>
    <Icon
      icon="feather:chevron-left"
      // TODO: convert to AMP
      on={`tap:AMP.navigateTo(url='${ROUTES.homeworkHelp}')`}
      svgClassName={styles.svg}
      {...iconProps}
    />
  </Link>
);

HistoryBackButton.propTypes = {
  iconProps: shape(),
  iconOnClick: func,
};

HistoryBackButton.defaultProps = {
  iconOnClick: goBack,
};

export default withAmpStyles(HistoryBackButton, s, stylesObj, ampStylesObj);
