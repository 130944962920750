import React from 'react';
import { bool, func } from 'prop-types';
// import dynamic from 'helpers/dynamic';
import { withModal, modalContextPropTypes } from 'oc-core-components/src/Modal/ModalProvider';
import { compose, pure } from 'recompose';

import Component from './ModalWrapper';

// const Component = dynamic(
//   () => import(/* webpackChunkName: "chunk_ModalWrapper" */ './ModalWrapper'),
// );

const DynamicModal = ({
  modal,
  ...restProps
}) => {
  const isDisplayed = modal.activeModal === restProps.name;

  return isDisplayed ? <Component {...restProps} /> : null;
};

DynamicModal.propTypes = {
  loading: func,
  component: func,
  ssr: bool,
  children: func,
  render: func,
  modal: modalContextPropTypes,
};

DynamicModal.defaultProps = {
  ssr: false,
  loading: () => null,
};

export default compose(
  withModal,
  pure,
)(DynamicModal);
