import React, { forwardRef } from 'react';
import { tagType } from 'types';
import { string } from 'prop-types';
import { createMarkup } from 'helpers/utils';

const RawContent = forwardRef(({
  tag: Tag,
  children,
  ...restProps
}, ref) => (
  <Tag
    ref={ref}
    /* eslint-disable-next-line react/no-danger */
    dangerouslySetInnerHTML={createMarkup(children)}
    {...restProps}
  />
));

RawContent.propTypes = {
  tag: tagType,
  children: string,
};

RawContent.defaultProps = {
  tag: 'div',
};

export default RawContent;
