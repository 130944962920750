import Modal from 'oc-core-components/src/Modal';
import dynamic from 'helpers/dynamic';

import React from 'react';

const ModalWrapper = ({
  modalComponent,
  dynamicComponent,
  component,
  render,
  ssr,
  loading,
  ...restProps
}) => {
  const Component = dynamicComponent || dynamic(
    component?.default || component,
    { ssr, loading },
  );

  return (
    <Modal component={modalComponent} {...restProps}>
      {
        (...modalArgs) => render(Component, ...modalArgs)
      }
    </Modal>
  );
};

ModalWrapper.propTypes = {};

export default ModalWrapper;
