
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { postDismissAskQuestionResultPopup } from 'common/api';

import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { useRouter } from 'oc-core-components/src/RouterProvider';

import CustomModalPortal from 'shared/components/SimpleModalWrapper';
// import AskQuestionV2RootContainer from 'components/pages/AskQuestionV2/AskQuestionV2RootContainer';
import dynamic from 'helpers/dynamic';

import { ENFORCE_HTML_PARAM, ASK_QUESTION_PARAM } from 'helpers/constants';
import { getV2AskedQuestion } from 'v2/redux/meta/selectors';
import { stylesProxy } from 'helpers/css';
import stylesObj from './useAskQuestionModal.module.scss';

const styles = stylesProxy(stylesObj, 'useAskQuestionModal');

const AskQuestionV2RootContainer = dynamic(
  () => import(/* webpackChunkName: "chunk_AskQuestionV2RootContainer" */ 'components/pages/AskQuestionV2/AskQuestionV2RootContainer'),
);


// pure function - import it in utils where u can't use the useAskQuestionModal hook
export const callAskQuestionModal = ({
  inline, isMobile, skipToResult, ...restProps
}) => {
  // consol.log(1111);

  inline({
    content: () => (
      <AskQuestionV2RootContainer
        skipToResult={skipToResult}
        {...restProps}
      />
    ),
    wrapperProps: {
      size: 'xlg',
      className: styles.wrapper,
      innerClassName: styles.wrapper__inner,
    },
    CustomModalPortal: isMobile ? CustomModalPortal : undefined,
  });
};

const useAskQuestionModal = ({
  userId,
} = {}) => {
  const { inline } = useModal();
  const isMobile = useMobile();

  return useCallback(
    ({ skipToResult } = {}) => {
      callAskQuestionModal({
        inline, isMobile, skipToResult, userId,
      });
    },
    [isMobile, inline, userId],
  );
};

// this hook is used to open AskQuestion modal as a result of the following sequence:
// 1. The user is not logged in
// 2. The user is clicking "Ask Question" button somewhere (HH index, HH show, Navbar plus list item)
// 3. The user is submitting the question and is being redirected to the Login page (because the use isn't logged in)
// 4. The user is submitting LogIn form on /login.en.html
// 5. The user is redirected to HH index / HH show page with preloaded AskQuestionModal with step3 state
export const useResultAskQuestionModal = () => {
  const askQuestionModal = useAskQuestionModal();
  const askedQuestion = useSelector(getV2AskedQuestion);

  useEffect(() => {
    if (!askedQuestion) return;

    async function showAskQuestionModal() {
      await postDismissAskQuestionResultPopup();

      askQuestionModal({ skipToResult: true });
    }

    showAskQuestionModal();
  }, [askedQuestion, askQuestionModal]);
};

// This hook is used to show the modal when visiting a page with ?ask-question=true
//
// This is also used to show AskQuestionModal for the users clicking "AskQuestion" on AMP pages
// 1. The user is clicking "AskQuestion" on AMP page
// 2. The user is being redirected to the same page but with additional params (HTML version instead of AMP version)
//    - ask-question (to preload modal on page load)
//    - enforce_html (a param required for a backend to return the content for HTML version of the page
//      instead of AMP version of the page in the API data)
// 3. AskQuestion modal is called on page load
// 4. The query params from (2) are erased from the address url
export const usePreloadAskQuestionModal = ({ callback }) => {
  const { pushRoute, router } = useRouter();
  const { query } = router;

  useEffect(() => {
    if (query[ASK_QUESTION_PARAM] === 'true') {
      pushRoute({
        queryUpdates: {
          [ASK_QUESTION_PARAM]: undefined,
          [ENFORCE_HTML_PARAM]: undefined,
        },
        replace: true,
      });
      callback();
    }
  }, [callback, query, router]);
};

export default useAskQuestionModal;
