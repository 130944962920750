import { getImage, isNil } from 'helpers/utils';
import isEqual from 'react-fast-compare';
import urljoin from 'url-join';
import { logWarning } from './utils';

// TODO: handle allowedSizes properly
export const extToSrcset = ({
  path, filename, ext, allowedSizes,
}) => {
  if (isEqual(allowedSizes, ['1x'])) {
    return (
      [
        getImage(`${path}${filename}.${ext}`),
        ' 1x, ',
      ].join('')
    );
  }
  if (isEqual(allowedSizes, ['2x'])) {
    return (
      [
        getImage(`${path}${filename}@2x.${ext}`),
        ' 2x, ',
      ].join('')
    );
  }

  if (isEqual(allowedSizes, ['1x', '2x'])) {
    return (
      [
        getImage(`${path}${filename}.${ext}`),
        ' 1x, ',
        getImage(`${path}${filename}@2x.${ext}`),
        ' 2x',
      ].join('')
    );
  }

  return (
    [
      getImage(`${path}${filename}.${ext}`),
      ' 1x, ',
      getImage(`${path}${filename}@2x.${ext}`),
      ' 2x, ',
      getImage(`${path}${filename}@3x.${ext}`),
      ' 3x',
    ].join('')
  );
};

const getImagePropsFactory = path => ({
  alt,

  filename,
  defaultExt = 'jpg',
  getDefaultSrc,
  withSources = true,
  sourcesExt = 'webp',
  sourcesMimeType = 'image/webp',
  allowedSizes = [],
}) => {
  try {
    if (!(path && filename && !isNil(alt))) {
      logWarning('Missing required attributes for image:', { alt, filename, path });
    }
    if ((path && path.split('').pop() !== '/')) {
      logWarning('Wrong path format:', { path });
    }

    const src = getDefaultSrc ? getDefaultSrc({
      getImage, path, filename, defaultExt,
    }) : getImage(urljoin(path, `${filename}.${defaultExt}`));

    // srcset for default extension
    const defaultExtSrcSet = defaultExt !== sourcesExt ? {
      srcSet: extToSrcset({
        path, filename, ext: defaultExt, allowedSizes,
      }),
      type: `image/${defaultExt}`,
    } : undefined;


    const sources = withSources ? [
      {
        srcSet: extToSrcset({
          path, filename, ext: sourcesExt, allowedSizes,
        }),
        type: sourcesMimeType,
      },
    ] : undefined;

    // additional fallback for default extension if we have webp
    if (sources && defaultExtSrcSet) {
      sources.push(defaultExtSrcSet);
    }

    return ({
      src,
      alt,
      sources,
    });
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default getImagePropsFactory;
