import React from 'react';
import { string } from 'prop-types';
import cn from 'classnames';
import withPortal from 'oc-core-components/src/WithPortal';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SimpleModalWrapper.module.scss';

const styles = stylesProxy(stylesObj, 'SimpleModalWrapper');

const SimpleModalWrapper = ({
  children,
  className,
  innerClassName,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.inner, innerClassName)}>
        {
          children
        }
      </div>
    </div>
  );
};

SimpleModalWrapper.propTypes = {
  innerClassName: string,
};

export default withPortal({ root: 'modal-root' })(SimpleModalWrapper);
